import React from "react"
import FirstStepFirstTab from "../components/firstStepFirstTab/FirstStepFirstTab";

const IndexPage = () => {
	return (
		<>
			<FirstStepFirstTab/>
		</>
	)
};

export default IndexPage
